import React, {useEffect} from "react"
import Layout from "../components/layout"
import Upload from "../components/upload"
import Seo from "../components/seo"
import {Amplify, Auth, Storage} from "aws-amplify";
import {navigate} from "gatsby";

const getAmplifyConfig = async () => {
    let domain = "picster.ai";

    return (await fetch(`https://${domain}/amplify-config.json`)).json();
}

Amplify.configure(await getAmplifyConfig());

const Introduction = () => {

    useEffect(async () => {
        try {
            await Auth.currentSession();

            if (await hasSuggestions()) {
                navigate("/suggestions");
            }

        } catch (e) {
            navigate("/");
        }
    }, []);

    const hasSuggestions = async () => {
        const suggestionContents = await Storage.list('suggestions/', {level: 'private'});

        return suggestionContents.results.length > 0;
    }

    return (
        <Layout pageTitle="Introduction">
            <Seo title="Introduction" />
            <Upload />
        </Layout>
    )
}

export default Introduction
