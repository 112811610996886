import React, {useState} from "react";
import {Container} from "react-bootstrap";
import {navigate} from "gatsby"
import HorizontalSpacer from "./horizontal-spacer";
import {Amplify, API, Auth, Storage} from "aws-amplify";
import BlockTitle from "./block-title";
import logoImage from "../assets/images/logo.png";
import {notify} from "../graphql/mutations";

const getAmplifyConfig = async () => {
    let domain = "picster.ai";

    return (await fetch(`https://${domain}/amplify-config.json`)).json();
}

Amplify.configure(await getAmplifyConfig());

let files = [];
let uploadedS3Keys = [];

const Upload = () => {

    const [isDragging] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [previewImages, setPreviewImages] = useState([]);

    const dropTargetReference = React.createRef();

    const generateRandomString = () => {
        return [...Array(8)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
    }

    const handleClickToUpload = (event) => {
        beginUploadProcess([...event.target.files]);
    };

    const beginUploadProcess = (newFiles) => {
        files = removeDupes(newFiles);

        generatePreviewImages();
        uploadFiles().then(result => console.log(result));
    };

    const removeDupes = (newFiles) => {
        let newFilesTemp = [];

        for (let newFile of newFiles) {
            if (!files.find(file => file.name === newFile.name)) {
                newFilesTemp.push(newFile);
            }
        }

        return newFilesTemp
    };

    const canMoveToNext = () => {
        return (files.length > 0) && (files.length === uploadedS3Keys.length);
    }

    const uploadFiles = async () => {
        setIsLoading(true);

        for (let i = 0; i < files.length; i++) {
            try {
                let key = `uploads/${generateRandomString()}/${files[i].name}`;
                await Storage.put(key, files[i], {
                    level: 'private',
                    contentType: files[i].type,
                    tagging: 'object:purpose=ia-training-image',
                    useAccelerateEndpoint: true,
                    progressCallback(progress) {
                        console.log(progress);
                    },
                });

                uploadedS3Keys.push(key);
            } catch (error) {
                console.log(error);
            }
        }

        setIsLoading(false);
    }

    const generatePreviewImages = () => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!file.type.startsWith('image/')) continue;

            const reader = new FileReader();
            reader.onload = (event) => {
                setPreviewImages((oldPreviewImages) => [...oldPreviewImages, event.target.result]);
            };
            reader.readAsDataURL(file);
        }
    }

    const handleNext = async (event) => {
        event.preventDefault();

        if (canMoveToNext()) {
            navigate("/interests");
            try {
                const credentials = await Auth.currentUserCredentials();
                const graphqlApiResponse = API.graphql({
                    query: notify,
                    authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
                    variables: {
                        message: `uploaded ${files.length} images, identity ID is ${credentials.identityId}`
                    }
                });
                if (graphqlApiResponse instanceof Promise) {
                    const result = await graphqlApiResponse;
                    console.log(result.data);
                }
            } catch (e) {
                console.log(e);
            }
            return;
        }

        alert("Please upload images and give us a moment to finalize the upload");
    };

    const renderEmptyUploadContainer = () => {
        return <div className="box__input">
            <svg className="box__icon" xmlns="http://www.w3.org/2000/svg" width="50"
                 height="43" viewBox="0 0 50 43">
                <path
                    d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
            </svg>
            <input onChange={handleClickToUpload} type="file" name="myfiles" id="myfiles" className="box__file" multiple />
            <label htmlFor="myfiles"><strong>Click to Upload</strong></label>
        </div>;
    }

    const renderThumbnails = () => {
        return previewImages.map((thumbnail, index) => {
            return <div key={index} className="content-preview-item">
                {/*{(thumbnail.progress < 100) && <CircularProgressbar styles={this.renderUploadProgressIndicatorStyles()} className="upload-loading-indicator" value={thumbnail.progress} /> }*/}
                <img alt='' key={index} src={thumbnail} className="" />
                <br/>
                <span>{thumbnail.name}</span>
            </div>
        });
    }

    return (
        <section className="subscribe-style-one center-children-horizontal-vertical">
            <Container>
                <BlockTitle
                    textAlign="center"
                    image={logoImage}
                    title={``}
                    showImage={true}
                />
                <h5 className="text-center">
                    Upload a diverse set of images which feature you in your favorite moments. Note that Fanster will tend suggest content which is similar to what you upload.
                </h5>
                <HorizontalSpacer height={50} />
                <div className="upload-container">
                    <div ref={dropTargetReference} id="upload-files"  className={isDragging ? "box has-advanced-upload is-dragover" : "box has-advanced-upload"}>
                        {(previewImages.length === 0 ? renderEmptyUploadContainer() : renderThumbnails())}
                    </div>
                </div>
                <HorizontalSpacer height={50} />
                <form className="subscribe-form contact-form-vaidated">
                    <button onClick={handleNext} className={isLoading ? "button-loading" : 'next-button'} type="submit">Next</button>
                </form>
            </Container>
        </section>
    );
};

export default Upload;
